@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  height: 100vh;
  background: var(--body-color);
  transition: var(--tran-05);
  font-family: 'Poppins';
}
:root {
  /*===== Colors ===== */
  --body-color: #e4e9f7;
  --body-secondary-color:rgb(198, 235, 213);
  --sidebar-color: #fff;
  --primary-color:rgb(5, 117, 33);
  --primary-color-light: #f6f5ff;
  --toggle-color: #ddd;
  --text-color: #707070;

  /* ===== Transition ==== */
  --tran-02: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.4s ease;
  --tran-05: all 0.5s ease;
}

/* ==== Reusable Css */
.sidenav .text {
  font-size: 13px;
  font-weight: 500;
  color: var(--text-color);
  transition: var(--tran-04);
  white-space: nowrap;
  opacity: 1;

}

.sidenav .image {
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;;
}


.sidenav {
    /* width: 250px;
    
    transition: width 0.3s ease-in-out;
    height: 100vh;
    background-color: rgb(10,25,41);
    padding-top: 28px;
    transition-property: width; */
     position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  /* padding: 10px 14px; */
  padding-top: 28px;
  background: var(--sidebar-color);
  transition: var(--tran-05);
  z-index:100;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}

 
.sidenavClosed {
    composes: sidenav; 
    width: 92px;
}

.mainOuter{
  width: 100%; 
  margin-left: 250px;
  padding:20px;
  transition: var(--tran-05);
 
}
.mainOuterFullWidth{
  width: 100%; 
  margin-left: 88px;
  padding:20px;
  transition: var(--tran-05);
 
}


/* header */
.sidenav header {
  position: relative;
  margin-bottom: 20px;
 
}

.sidenav header .imageText img {
  width: 40px;
  border-radius: 6px;
}

.sidenav header .imageText {
  display: flex;
  align-items: center;
  /* transition-property: font-size;
  transition: var(--tran-04) */
}

.sidenav header .imageTextClosed{
    font-size: 0px;
}

.headerText .name {
  font-weight: 600;
  transition-property: font;
  transition: var(--tran-04);
}

.headerText .nameClosed {
  font-size:0px;
}

.headerText .profession {
  margin-top: -2px;
  font-size: small;
  transition: var(--tran-04);
}
.headerText .professionClosed {
  font-size: 0px;
}
 
.sideCategory{
    display: flex;
    flex-direction: row;
    /* justify-content: center;
    align-items: center; */
    
}
.sideitem {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
    color: #B2BAC2;
    text-decoration: none;
    transition:var(--tran-02)
    
}
.sideitem a{
    text-decoration: none;
    color: #B2BAC2;
    transition: var(--tran-02);
    width:100%;
    height: 100%;
    display: block;
}


.SideNavDropdown{
     display: flex;
    align-items: center;
    background:var(--body-secondary-color);
    color: var(--text-color);
    text-decoration: none;
    /* margin: 1px; */
    padding:10px;
    font-size: 90%;
    transition: var(--tran-05);
    
   
}

.SideNavDropdown a{
  
    color: var(--text-color);
    display: block;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    transition: var(--tran-05);
    
    
}
.SideNavDropdown:hover{
   
    background-color: var(--primary-color);
    color: var(--sidebar-color);
    

    /* text-decoration: none; */
}
 
.SideNavDropdown:hover a{
   
    
    color: var(--sidebar-color);
    display: block;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;

    /* text-decoration: none; */
}
 
.SideNavDropdownClosed{
    height: 0;
    width: 0;
    font-size: 0;
    
}
.linkText {
     transition: width 6s;
    padding-left: 16px;
    transition:var(--tran-05);
    color:var(--text-color)
    
}

.linkTextClosed {
     transition:var(--tran-05);
    font-size:0px;
    

}



 
/* .linkTextClosed {
    composes: linkText;
    visibility: hidden;
} */
 
.sideitem:hover{
    background-color:var(--primary-color);
    color: var(--sidebar-color);
}
 .sideitem:hover .icon,
 .sideitem:hover .linkText{
    
    color: var(--sidebar-color);
}
 
.menuBtn {
    /* align-self: center;
    align-self: flex-start;  
    justify-self: flex-end;
    color: #B2BAC2;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding-left: 20px; */
    
   
  position: absolute;
  top: 10%;
  right: 5px;
  transform: translateY(-50%);
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* border-radius: 50%; */
  /* color: var(--sidebar-color); */
  /* font-size: 22px; */
  /* transition: var(--tran-03);
  z-index: 102; */

}

.dropDownList{

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.dropDownListItem{
    position: relative;
   list-style: none;
    margin-top:5px;
    margin-bottom:5px;
    margin-left: 10%;
}

.dropDownListItem_icon .dropDownListItem_text{
 color:#fff;
  transition: var(--tran-02);
}

.dropDownListItem a{
    text-decoration: none;
    display: block;
    columns:var(--text-color);
   
}

.icon{
  color: var(--text-color);
  transition: var(--tran-02);
}
.icon:hover{
  color: var(--sidebar-color);
  transition: var(--tran-02);
}
.bottomContent{
  position:relative;
  margin-bottom: 0px;
  padding: 0;
  display:flex;
  flex-direction:column; 
 width:100%;
 color:var(--primary-color)
}
.searchPanel{
  padding: 10px;
  margin:10px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  
  flex-wrap: wrap;
  align-items:flex-start;
  border: 1px solid var(--primary-color-light);
  width: 100%;
  transition: var(--tran-02);
  border-radius: 10px;
}
.searchContent{
  padding: 10px;
  margin:10px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  
  flex-wrap: wrap;
  align-items:flex-start;
  border: none;
  width: auto;
  transition: var(--tran-02);
 
}
.searchPanel select{
  padding:5px;
    margin:5px 5px;
    min-width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
    color:rgb(80, 77, 77);
    background-color: #fff;
}


.mainContent{
  padding: 2px;
  margin:10px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items:flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  
  border: 1px solid #ccc;
  width: 99%;
  transition: var(--tran-02);
  border-radius: 5px;
}
.topSubContent{
  border-bottom: 1px solid #ccc;
  width: 100%;
  display: block;
}
.topSubContent h3{
  margin:5px;
  color:var(--primary-color);
  padding: 5px;
  font-weight: 400;
}
.topContent h2{
  margin:5px;
  color:var(--primary-color);
  padding: 5px;
  font-weight: 400;
  width: 100%;
}

.topContent h3{
  margin:5px;
  color:var(--primary-color);
  padding: 5px;
  font-weight: 400;
}
.topContenth3{
  margin:5px;
  color:var(--primary-color);
  padding: 5px;
  font-weight: 400;
}
.subHeader{
   margin:5px;
  color:var(--primary-color);
  padding: 5px;
  font-weight: 400;
  font-size: 16px;
}

.formSubContent{
  
  margin:10px;
  max-width: 300px;
}

/* table styles */
#general_table{
  border-collapse: collapse;
  width:100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size:13px;
  color:#525050
}

#general_table th, #general_table td{
  border-bottom: 1px solid #ddd;
  padding: 3px;
}

#general_table th{
  /* background-color:rgb(143, 189, 157); */
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #ddd;
  text-align: left;


}
.footerRow{
   background-color: #ddd;
   font-weight: 600;
}

/* #general_table tr:nth-child(even){
  background-color: #ddd;
} */

#general_table tr:hover{
  background-color: rgb(222, 247, 229);
}

.displayContentContainer{
display:flex;
justify-content: flex-start;
align-items: center;
}

.displayContentChild{
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 align-items: flex-start;
 margin:10px;
}
@media only screen and (max-width: 600px) {
  .formSubContent{
    width: auto;
  }
}






