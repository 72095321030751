@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  height: 100vh;
  background: var(--body-color);
  transition: var(--tran-05);
}
:root {
  /*===== Colors ===== */
  --body-color: #e4e9f7;
  --body-secondary-color: rgb(198, 235, 213);
  --sidebar-color: #fff;
  --primary-color: rgb(5, 117, 33);
  --primary-color-light: #f6f5ff;
  --toggle-color: #ddd;
  --text-color: #707070;

  /* ===== Transition ==== */
  --tran-02: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.4s ease;
  --tran-05: all 0.5s ease;
}

.container {
  position: relative;
  width: 100%;
  display: flex;
  padding: 0px;
  margin: 0px;
  border: 1px solid #ccc;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  background-color: #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 10px 10px 5px lightblue;
}
/* 
.item {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  padding: 50px;
  width: auto;
  background-color: #ddd;
  row-gap: 2em;
}
.itemRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 300px;
  margin: 0px;
} */
.buttonContainer {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

input,
select,
text {
  padding: 5px;
  margin: 5px 5px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
  color: rgb(80, 77, 77);
  background-color: #fff;
}
input:read-only {
  background-color: aliceblue;
}

label {
  padding-left: 5px;
  color: rgb(10, 100, 100);
  min-width: 100%;
}
form {
  border-radius: 10px;
}
.submitButton {
  margin-top: 25px;
  padding: 5px;

  background-color: rgb(51, 112, 27);
  color: white;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  width: 100%;
}
.submitButton:hover {
  padding: 5px;
  width: 100%;
  background-color: rgb(164, 207, 147);
  color: rgb(27, 27, 27);
  border: none;
  cursor: pointer;
}

.loginButton {
  width: 100%;
  border: none;
  padding: 10px 8px;
  background: green;
  color: #fff;
  border-radius: 5px;
  margin: 0px;
}

.loginMessage {
  padding: 14px;
  color: crimson;
  font-size: 12px;
  text-align: center;
}

