input,select,text,textarea{
    padding:3px;
    margin:2px;
    min-width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
    color:rgb(80, 77, 77);
    background-color: #fff;
    
}
input:read-only{
    background-color: aliceblue;
}

label{
    padding-left:5px;
   color:rgb(10, 100, 100);
    min-width: 100%;
}
form{
 
  border-radius: 10px;
}
.submitButton{
    padding:10px;

    background-color: rgb(51, 112, 27);
    color:white;
    border: none;
    cursor: pointer;
    text-transform:uppercase;
    border-radius: 5px;
    font-weight: 500;
    font-size: 13px;
    
}
.submitButton:hover{
    padding:10px;
    height: 50px;
    background-color: rgb(164, 207, 147);
    color: rgb(27, 27, 27);;
    border: none;
    cursor: pointer;
    
}
.formInput{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap:0px;
   font-size: 11px;
    margin:2px;
    width: auto;

}
.search_box{
     display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
   font-size: 13px;
    margin:5px;
    width: 50%;
    
}
.search_box input{
padding:5px;
font-size:14px    
}

.formInputCheckbox{
    display: inline;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   font-size: 13px;
    margin:10px;
    width: auto;
    

}


.errorSpan{
    font-size: 12px;
    padding:2px;
    padding-left:5px;
    color:crimson;
    display: none;
} 
.error_span {
  font-size: 12px;
  padding: 2px;
  padding-left: 5px;
  color: red;
  display: block;
}
.inputError{
  border: 1px solid red;
}
input:invalid[focused="true"]{
    border: 1px solid red;
}
input:invalid[focused="true"] ~ span{
    display: block;
}


.success_box{
    
            padding: 10px;
            width: 99%;
            margin: 10px;
            box-sizing: border-box;
            background-color: #def5ed;
            border-left:  solid green;
           
        
}
.error_box{
    
            padding: 10px;
            width: 99%;
            margin: 10px;
            box-sizing: border-box;
            background-color: #ffe3e3;
            border-left:  solid crimson;
            width:100%
        
}
.loader_box{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.item_list{
    padding:5px;
    margin:1px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    width:100%;
    background-color:#e7e7e7
}
.item_list:hover{
    background-color: #fff;
}

.list_item{
    margin-right: 10px;
    font-size: 12px;
}
.list_item h6{
   
    font-size: 12px;
}
.list_item input{
   padding:5px;
   
}
