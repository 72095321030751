@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&family=Poppins:wght@200;300;400;500;600&display=swap');
body {
  margin: 0;
  padding: 0;
  background-color: whitesmoke;
  font-family:'Poppins';
}
.footer {
  /* position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f5f5f5;
  
  text-align: center;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  border-top: 1px solid #ddd;
  font-size: 11px; */

  padding: 2px;
  position: fixed;
  font-family:Poppins;
  font-size: 11px;
  line-height: 1.6;
  word-spacing: normal;

  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
}
.nav {
  list-style-type: none;
  margin: 0;

  padding: 10;
  overflow: hidden;
  background-color: #04aa6d;

  top: 0;
  width: 100%;
  border-bottom: 1px solid #ddd;
  position: sticky;
}

.nav li {
  float: left;
}

.nav li a {
  display: block;
  color: #fff;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}
.nav li a:hover:not(.active) {
  background-color: #fff;
  color: black;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: auto;
  width: 90%;
}
.column {
  float: left;
  padding: 8px;
  margin: 10px;
}
.column.side {
  width: 15%;
  background-color: white;
  border-radius: 5px;
  min-height: 70vh;
  max-height: auto;
}
.column.right {
  width: 15%;
  min-height: 70vh;
  max-height:auto;
}
.column.middle {
  width: 70%;
  background-color: white;
  border-radius: 5px;
  min-height: 70vh;
  max-height: auto;
}

.active {
  background-color: #04aa6d;
}

.sidelist ul {
  list-style-type: none;
}
.sideitems {
  display: block;
  padding: 0;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 12px;
}
.sideitems button {
  display: block;
  background-color: white;
  border: none;
  width: 100%;
  text-align: left;
  padding: 16px 5px;
}
.sideitems button:hover {
  background-color: #ddd;
  cursor: pointer;
}
.sideitems li:last-child {
  border-bottom: none;
}
.header_title {
  display: block;
  border-bottom: 2px solid #04aa6d;
  font-size: 14px;
  text-transform: uppercase;
  padding: 5px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.body_text {
  padding: 5;
  text-align: left;

  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  line-height: 1.6;
  word-spacing: normal;
}
.formWrapper{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.formInput{
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: flex-start;
  gap:10px
}
input,
select,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  box-sizing: border-box;
}
/* input[type=textarea] {
  width: 70%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  box-sizing: border-box;
} */
input[type="radio"] {
  width: 10%;

  border: 1px solid #ccc;

  margin-top: 6px;
  box-sizing: border-box;
}

.radio_header {
  width: 10%;
  margin-top: 15px;
  box-sizing: border-box;
  align-items: center;
  display: block;
}

label {
  padding: 3px 3px 3px 0;
  display: inline-block;
  box-sizing: border-box;
  font-size: 12px;
}

input[type="submit"] {
  background-color: #04aa6d;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  box-sizing: border-box;
}

input[type="submit"]:hover {
  background-color: #45a049;
  box-sizing: border-box;
}

.col-60 {
  float: left;
  width: 60%;
  margin-top: 2px;
  box-sizing: border-box;
}

.col-20 {
  float: left;
  width: 28%;
  margin-top: 6px;
  box-sizing: border-box;
}
.col-80 {
  float: left;
  width: 70%;
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
}

.col-40 {
  float: left;
  width: 40%;
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
}
.col-100 {
  float: left;
  width: 100%;
  margin-top: 6px;
}
.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
  box-sizing: border-box;
}
.col-50 {
  float: left;
  width: 50%;
  margin-top: 6px;
}
.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
  box-sizing: border-box;
}
.questions {
  border-bottom: 1px solid #ccc;
}

/*course units*/

.unitsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px;
  cursor: pointer;
}

.unitsWrapper:hover {
  background-color: #def5ed;
  border-left: 3px solid green;
  
}

@media screen and (max-width: 600px) {
  .container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .loginInner{
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-grow: 1;
  
  }

  .loginOuterSecondary{
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    height: 100%;
    justify-content: flex-start;
   
  
  }
   .loginOuterPrimary{
    flex-direction: column;
   
    padding:0;
    margin:0;
   
    justify-content: flex-start;
    align-items: center;
  
   
  
  }
  .item{
    padding:10px;
    width:100%;
    flex-grow: 1;
    height: 100%;
  }
  .itemRight{
    width:100%;
    padding:10;
    flex-grow: 1;
    height: 100%;
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    
  }
  .imageWrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  }
  .column.side,
  .column.middle,
  .column.right {
    width: 100%;
  }
  .col-25,
  .col-40,
  .col-50,
  .col-60,
  .col-75,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
  
  
}


/*login styling*/

.loginInner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 400px;
}

.loginOuterPrimary {
  height: 100vh;
  width: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.loginOuterSecondary {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius:8px
  /* flex-wrap: wrap;  */
  
}


.item {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  padding: 50px;
  width: 100%;
  background-color: #ddd;
  row-gap: 2em;
}
.itemRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 300px;
  margin: 0px;
  flex-grow: 1;
}
.imageWrapper{
   display: flex;
                  justify-content:center;
                  align-items:center;
                  /* flex-grow: 1; */
}


  .button.secondary a {
    padding: 8px 8px;
    margin: 0;
    background-color: #507b50;
    color: #ffffff;
    border:none;
    border-radius:5px
  }
  .button.secondary a:hover {
    background-color: #97cc8d;
  }